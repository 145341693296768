































































































































































































































































































import { getWorkById, GetWorkByIdReq, GetWorkByIdRes, Work } from "@/api/work";
import { saveCmd, CmdReq } from "@/api/robotcmd";
import { globalData } from "@/utils/config";
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { CommonRes } from "@/api/common";
import UploadBagLogDialog from "@/components/UploadBagLogDialog.vue";
import { getRobotById, GetRobotByIdReq, GetRobotByIdRes} from "@/api/robot";
import { jsonParse } from "@/utils/utils";
import MqttClientM from "@/mqtt/mqtt";
import { getUserInfo, GetUserInfoRes, UserInfo } from "@/api/user";
import TipDialog from "@/components/TipDialog.vue";
import {
  GetWorkReq,
  exportSigleTask,
} from "@/api/work";
@Component({
  filters: {
    dealStatusColorFilter(val: number) {
      switch (val) {
        case 1:
          return "statusTextBlue";
        case 2:
          return "statusTextYellow";
        case 3:
          return "statusTextRed";
        default:
          return "statusTextRed";
      }
    },
    dealIconFilter(val: number) {
      switch (val) {
        case 1:
          return require("@/assets/img/ok1.png");
        case 2:
          return require("@/assets/img/cancel.png");
        default:
          return require("@/assets/img/error2.png");
      }
    },
  },
  components: {
    UploadBagLogDialog,
    TipDialog
  },
})
export default class Index extends Vue {
  private robotId: string = "";
  private name: string = "";
  private id: number = 0;
  private picPref: string = globalData.picUrl;
  private img: string = "";
  private taskDuration: string= "";
  private isLock: boolean = false;
  private showDialog: boolean = false;
  private showUplaodPop: boolean = false;
  private userInfo: any = {};
  private showExportDialog: boolean = false;
  private work: Work = {
    id: 0,
    robotId: "",
    reportName:"",
    taskName: "",
    picture: "",
    taskType: 0,
    taskResult: 0,
    taskDuration: 0,
    taskArea: 0,
    completionRate: 0,
    startTime: "",
    stopTime: "",
    mapName: "",
    longitude: "",
    latitude: "",
    robotEntity: {
      name: "",
      robotId: "",
    },
  };
  created() {
    console.log("robotId: " + this.$route.params.robotId);
    this.name = this.$route.params.robotName;
    let taskid = this.$route.params.id;
    this.robotId = this.$route.params.robotId;
     if(this.robotId == undefined || this.robotId == null){
       this.robotId = this.getQueryString("rid");
      console.log("getQueryString robotId: " + this.robotId);
    }
    if (this.robotId == undefined || this.robotId == null || this.robotId.length == 0) {
      this.$router.go(-1);
      return
    }
    if(this.name == undefined || this.name == null){
       this.name = this.getQueryString("nm");
        console.log("getQueryString name: " + this.robotId);
    }
    if(taskid == undefined || taskid == null){
       taskid = this.getQueryString("tid");
       console.log("getQueryString taskid: " + taskid);
    }
    this.getWorkList(Number(taskid));
    this.getpermission();
  }
  private uploadBagLog () {
    this.showDialog = true;
  }
  private async confirmEvent (data: any) {
      let is_upload_bag = false
      let is_upload_log = false
      console.log('confirmEvent data', data)
      if (data.result.length === 2) {
        console.log('confirmEvent AAA');
        this.showDialog = false;
        is_upload_bag = true
        is_upload_log = true
      } else if (data.result.length === 1) {
        console.log('confirmEvent BBB: ', data.result);
        this.showDialog = false;
        if (data.result.indexOf('bag') !== -1) {
          console.log('confirmEvent BBB111');
          is_upload_bag = true
        } else if (data.result.indexOf('log') !== -1) {
          console.log('confirmEvent 222');
          is_upload_log = true
        }
      } else {  
        console.log('confirmEvent CCC');
        Toast(this.$t("please_select_upload_type").toString())
        return
      }
      console.log('confirmEvent is_upload_bag', is_upload_bag)
      console.log('confirmEvent is_upload_bag', is_upload_log)
      console.log('interception_time', data.interception_time)
      let time = parseInt(data.interception_time)
      console.log('time AAA', time)
      if(time == undefined || time == null || isNaN(time)){ 
        time = 60
      }
      console.log('time BBB', time)
      let cmd = {
        'op': 'call_service',
        'service': 'upload_report_bag_and_log',
        'cid': 'bag_log_' + this.userInfo.id,
        'args': {
          'report_name': this.work.reportName,
          'is_upload_bag': is_upload_bag,
          'is_upload_log': is_upload_log,
          'bag_trim_time': time
        }
      }
      console.log('interception_time cmd',cmd)
    let req: GetRobotByIdReq = {
      robotId: this.work.robotId,
    };
    let res: GetRobotByIdRes = await getRobotById(req);
    console.log(res);
    if (res.code == 0) {
      console.log(res.robot);
      if (res.robot.robotEntity.stateText) {
        let json:any = await jsonParse("" + res.robot.robotEntity.stateText).catch(() => {
          console.log();
        });
        this.saveCmd(cmd, json.robot_state)
      }
    }
     // this.saveCmd(data, robot_state)
  }
  private toRobotDetail() {
    this.$router.push({
      path: "/robotDetail",
      name: "RobotDetail",
      params: {
        robotName: this.name,
        robotId: this.work.robotId,
      },
    });
  }
  private saveCmd(data: any, robot_state: number) {
    console.log('cmd', data)
    let state = 3
    if (robot_state === -1) {
      state = 0
    }
    let cmdReq1:CmdReq = {
      robotId: this.work.robotId,
      state: state,
      cmd: JSON.stringify(data),     
      remark: '',
      submitter: this.userInfo.username
    };
    saveCmd(
      cmdReq1
    ).then(res => {
      console.log(res)
      if (res.code === 0) {
        if (robot_state === -1) {
          Toast(this.$t("not_online_and_stored_on_the_server").toString())
        } else {
          data.args.remote_cmd_id = res.id
          MqttClientM.getInstance().publishMessage('/RobotOperation/' + this.work.robotId,  JSON.stringify(data))
          Toast(this.$t("command_sent").toString())
          // alert('ok')
        }
        // that.$Message.info(res.data.msg)
      } else {
        Toast(res.msg)
      }
    }).catch(err => {
        console.log(err)
    })
    // this.socket.send(JSON.stringify(cmd))
  }
  private cancelEvent () {
     this.showDialog = false;
  }
  private req: GetWorkReq = {
    id: 1,
    companyType: "0"
  };
  private async getpermission() {
    let userInfoStr = localStorage.getItem("userInfo");
    if(userInfoStr != null && userInfoStr.length > 0){  
        this.userInfo = JSON.parse(userInfoStr); 
    } 
    const res: GetUserInfoRes = await getUserInfo();
    if (res.code == 0) {
      const userinfo2: UserInfo = res.user;
      if (userinfo2.companyCode == 'MX' && this.userInfo.userType > 1) {
           this.showUplaodPop = true;
      }
    }
  }
  private exportReprort() {
    this.showExportDialog = true;
  }
  private canceExportEvent() {
    this.showExportDialog = false;
    this.isLock = false;
  }
  private async confirmExcel() {
    this.showExportDialog = false;
    if(this.isLock) {
      console.log("confirmExcel isLock")
      return
    }
    this.isLock = true
    setTimeout(() => {
      this.isLock = false;
    }, 8000);
    console.log("confirmExcel do export Task")
    //Toast(globalData.exportFileUrl + this.$t("exportTaskStart").toString());
    if(location.host == globalData.yjhUrl){
        this.req.companyType = "1"
    }
    this.req.id = this.work.id
    let res: CommonRes = await exportSigleTask(this.req);
    if (res.code == 0) {
      //Toast(this.$t("exportTaskSuccess").toString());
      console.log("globalData.exportFileUrl", globalData.exportFileUrl);
      Toast(this.$t("exporting_report").toString())
      return false
    }
  }
  private async getWorkList(id: number) {
    let req: GetWorkByIdReq = {
      id: id,
    };
    let res: GetWorkByIdRes = await getWorkById(req);
    console.log(res);
    if (res.code == 0) {
      this.work = res.task;
      this.img = this.picPref + this.work.picture;
      this.taskDuration =  this.work.taskDuration / 60 / 60 + "h";//formatsecd2Text(this.work.taskDuration);
      if (this.work.taskDuration > (60 * 30)) {
        this.taskDuration =  Number(this.work.taskDuration / 60 / 60).toFixed(1) + "h"
      } else if (this.work.taskDuration > 60) {
        this.taskDuration =  Number(this.work.taskDuration / 60).toFixed(0) + "min"
      } else {
        this.taskDuration =  this.work.taskDuration + "s"
      }
      console.log('this.work:', this.taskDuration);
    }
  }
  //http://192.168.0.23:8888/workDetail?tid=113&rid=MX-SIMULATION-002&nm=MX-002
  private  getQueryString(name:string):string {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    console.log("getQueryString name"+name, r);
    if (r != null) {
        return unescape(r[2]);
    }
    return "";
}
}
